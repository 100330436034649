import React, { VFC, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthCallback } from '../lib/auth'
import { LoadingPage } from '../components/global/LoadingPage'
import { InvalidCallbackPage } from '../components/global/InvalidCallbackPage'

const IndexPageRoute: VFC = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const { returnToCallback, isValidCallback } = useAuthCallback()

  useEffect(() => {
    if (isLoading) {
      return
    }
    ;(async () => {
      let token: string | undefined
      try {
        token = await getAccessTokenSilently({ ignoreCache: true })
      } catch (error) {
        // ignore error
      }
      if (token) {
        returnToCallback()
        return
      }
      navigate(`/register`, { replace: true })
    })()
  }, [isLoading, isAuthenticated, returnToCallback, getAccessTokenSilently])

  if (!isLoading && isAuthenticated && !isValidCallback) {
    return <InvalidCallbackPage />
  }

  return <LoadingPage />
}

export default IndexPageRoute
